import React from 'react';
import { Helmet } from 'react-helmet';

// components
import Container from '../../components/container';

import styles from './styles.scss';

const BrightRock = () => (
  <Container className={styles.container}>
    <Helmet>
      <title>Message from the CEO | Bon Sano</title>
    </Helmet>
    <h2>Message from the CEO</h2>
    <div>
      Dear Member
      <br />
      <br />
      As a partner in your financial future, Bon Sano is committed to keeping you informed of any
      new changes that affect your policy.
      <br />
      The new Insurance Act (the Act) brought about changes, one of which is that the Bon Sano
      Family Funeral Scheme no longer qualifies as a group scheme (as defined in the Act) and thus
      we are required to issue an individual policy to you. In line with the changes, we have
      changed our underwriters for your policy so we can assure you of a better service to meet your
      financial needs. We have appointed BrightRock Life Limited to be the underwriter of your
      funeral policy with effect from 1 June 2020. BrightRock Life Limited enjoys strong backing
      from its majority shareholder, Sanlam. BrightRock will also take over all premium collections
      via debit order and payroll deductions. <br />
      <br />
      We further inform you that there will be no increase in premium for 2020. Your policy benefits
      and terms and conditions will remain the same with an additional benefit of ‘repatriation’ of
      the deceased. Your new policy document will be sent to you as soon as possible starting in
      July 2020. The master policy document will be available at our office or can be viewed on our
      website. Please note in future all communication, must be addressed to the Bon Sano office
      telephonically at <a href="tel:+27219454363">(021) 945 4363</a> or in writing via email to{' '}
      <a href="mailto:dhusselmann@bonsano.co.za">dhusselmann@bonsano.co.za</a>. Letters can be
      posted to 8D Old Dutch Square, Bill Bezuidenhout Avenue, Bellville 7530. This include claims,
      queries and amendments to your policy.
      <br />
      <br />
      In order for us to communicate effectively and correctly with you, please contact our office
      to update your contact information.
      <br />
      <br />
      As a valued member, we would like to thank you for your loyalty. We look forward to continue
      delivering excellent service to you in years to come. <br />
      <br />
      Yours faithfully
      <br />
      <br />
      <strong>Doug Husselmann</strong>
      <br />
      Chief Executive Officer
    </div>
  </Container>
);

export default BrightRock;
